<template>
  <div class="contain bg" ref="contains">
    <div class="myOrder title_bg title_color" ref="refHeight">
      开门投柜
      <!-- <img class="closePage" @click="returnPrevPage" src="../../assets/imgs/closePage.png" /> -->
    </div>
    <img
      v-if="content"
      class="openDoorSuccess"
      src="../../assets/imgs/doorOpen.png"
    />
    <img
      v-else
      class="openDoorSuccess"
      src="../../assets/imgs/openDoorSuccess.png"
    />
    <span v-if="content">
      <p class="p1">柜门已打开</p>
      <p class="p2">
        <span>请在</span>
        <span
          style="
            color: #ff391b;
            font-weight: 600;
            font-size: 0.32rem;
            line-height: 0.44rem;
            margin: 0 0.08rem;
          "
        >
          {{ content }}
        </span>
        <span>内装好货物，并关紧所有柜门，倒计</span>
      </p>
      <p class="p2">时结束后将自动发车</p>
      <button class="b1" @click="closeDoor">我已装货并关门</button>
      <button
        :class="timerCount > 0 || openTime <= 0 ? 'b2 active' : 'b2'"
        @click="againOpenDoor"
        :disabled="timerCount > 0 || openTime <= 0 ? true : false"
      >
        柜门未打开，重新开门{{
          timerCount > 0
            ? `（${timerCount}s）`
            : openTime > 0
            ? `（${openTime}次）`
            : ""
        }}
      </button>
    </span>
    <span v-else>
      <p class="p1">投柜完成，即将配送</p>
      <p class="p3" style="background: #ffedea; color: #ff391b">
        <img class="warnnings" src="../../assets/imgs/wanrings.png" />
        <span>请关紧柜门，远离车辆，注意安全</span>
      </p>
      <button class="b1" @click="returnBack">我知道了</button>
    </span>

    <a class="customer" href="tel:400-666-7876">
      <img src="../../assets/imgs/customer.png" />
      <span>遇到困难？联系客服</span>
    </a>

    <Dialog
      v-if="showDialog"
      :dialogContent="dialogContent"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
    />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog.vue";
import Message from "vue-m-message";
import { openDoor, confirmForArk } from "@/api/api";

export default {
  name: "OrderList",
  components: {
    Dialog,
  },
  data() {
    return {
      timerCount: 0, //  点击柜门未开 显示的倒计时
      openTime: 3, // 开柜门次数
      orderCode: "",
      vin: "",
      showDialog: false,
      dialogContent: "确定投柜完成吗？",
      doubleClick: false,
      content: "00:00",
      countdownTime: 300,
      timer: null,
    };
  },

  watch: {},

  beforeCreate: function () {},

  created: function () {
    this.orderCode = this.$route.query.orderCode;
    this.vin = this.$route.query.vin;
    this.countdownTime = this.$route.query.countdownTime || 300;
    this.content = this.formatSeconds(this.countdownTime - 1);
    this.countdowm(new Date().getTime() / 1000 + Number(this.countdownTime));
  },

  mounted: function () {},

  destroyed() {
    clearInterval(this.timer);
  },

  methods: {
    againOpenDoor() {
      if (this.doubleClick) {
        return Message({
          title: "请不要操作太快！",
          type: "warning",
          collapsable: false,
        });
      }
      this.doubleClick = true;
      this.$loadingOpendoor.start("正在开门，请稍候");
      openDoor({
        orderCode: this.orderCode,
        vin: this.vin,
      })
        .then((res) => {
          this.doubleClick = false;
          this.timerCount = 10;
          this.startTimerCount(this.openTime);
          Message({
            title: "打开柜门成功！",
            type: "success",
            collapsable: false,
          });
        })
        .catch((err) => {
          this.timerCount = 10;
          this.doubleClick = false;
          this.startTimerCount(this.openTime);
          Message({
            title: err.msg,
            type: "warning",
            collapsable: false,
          });
        });
    },
    closeDoor() {
      this.showDialog = true;
    },

    handleOk() {
      this.$loadingCircle.start();
      this.showDialog = false;
      confirmForArk({
        orderCode: this.orderCode,
      }).then((res) => {
        clearInterval(this.timer);
        this.content = "";
        // this.$router.go(-1)
      });
    },

    returnBack() {
      this.$router.go(-1);
    },

    handleCancel() {
      this.showDialog = false;
    },

    returnPrevPage() {
      this.$router.go(-1);
    },

    // 重开柜门倒计时
    startTimerCount(value) {
      this.openTime--;
      if (this.openTime === 0) {
        this.timerCount = 0;
        return false;
      }
      let timer1 = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount--;
        } else {
          clearInterval(timer1);
        }
      }, 1000);
    },

    // 格式化初始时间
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var time = "" + parseInt(secondTime) + "";

      if (minuteTime > 0) {
        time = "0" + parseInt(minuteTime) + ":" + time;
      } else {
        time = "00" + ":" + time;
      }
      if (hourTime > 0) {
        time = "0" + parseInt(hourTime) + ":" + time;
      }
      return time;
    },

    // 装货时间倒计时
    countdowm(timestamp) {
      let self = this;
      self.timer = setInterval(function () {
        let nowTime = new Date();
        let endTime = new Date(timestamp * 1000);
        let t = endTime.getTime() - nowTime.getTime();
        if (t > 0) {
          let day = Math.floor(t / 86400000);
          let hour = Math.floor((t / 3600000) % 24);
          let min = Math.floor((t / 60000) % 60);
          let sec = Math.floor((t / 1000) % 60);
          hour = hour < 10 ? "0" + hour : hour;
          min = min < 10 ? "0" + min : min;
          sec = sec < 10 ? "0" + sec : sec;
          let format = "";
          if (day > 0) {
            format = `${day}:${hour}:${min}:${sec}`;
          }
          if (day <= 0 && hour > 0) {
            format = `${hour}:${min}:${sec}`;
          }
          if (day <= 0 && hour <= 0) {
            format = `${min}:${sec}`;
          }
          self.content = format;
        } else {
          clearInterval(self.timer);
          self.content = "";
          confirmForArk({
            orderCode: self.orderCode,
          }).then((res) => {
            console.log(res);
          });
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1.16rem;
  overflow: scroll;
  text-align: center;
  background: #ffffff;
  .myOrder {
    width: 100%;
    height: 1.16rem;
    line-height: 1.16rem;
    font-size: 0.3rem;
    margin: 0 auto;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    color: #000000;

    .closePage {
      width: 0.58rem;
      height: 0.58rem;
      position: absolute;
      left: 0.26rem;
      top: 0.28rem;
    }
  }

  .openDoorSuccess {
    width: 1.84rem;
    height: 1.84rem;
    margin: 1.2rem auto -0.2rem;
  }

  .p1 {
    font-size: 0.48rem;
    color: #000000;
    font-weight: 600;
    line-height: 0.83rem;
  }

  .p2 {
    line-height: 0.44rem;
    font-size: 0.28rem;
    color: #000000;
    margin: 0 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p3 {
    height: 0.64rem;
    line-height: 0.64rem;
    font-size: 0.28rem;
    color: #000000;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      height: 0.64rem;
      line-height: 0.66rem;
    }
    .warnnings {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.08rem;
    }
  }

  .b1 {
    width: 5.9rem;
    height: 0.92rem;
    line-height: 0.92rem;
    text-align: center;
    background: #436eff;
    border-radius: 0.46rem;
    margin: 0.8rem auto 0;
    color: #ffffff;
    font-size: 0.32rem;
    display: block;
    font-weight: 600;
  }

  .b2 {
    width: 5.9rem;
    height: 0.92rem;
    line-height: 0.92rem;
    text-align: center;
    border-radius: 0.46rem;
    margin: 0.28rem auto 0;
    font-size: 0.32rem;
    color: #436eff;
    box-sizing: border-box;
    border: 0.02rem solid rgba(67, 110, 255, 1);
    background: #ffffff;
    display: block;
    font-weight: 600;
    &.active {
      border: 0.02rem solid rgba(203, 213, 251, 1);
      color: #cbd5fb;
    }
  }

  .customer {
    width: 3.5rem;
    height: 0.64rem;
    line-height: 0.64rem;
    position: absolute;
    bottom: 0.52rem;
    left: 0;
    right: 0;
    margin: auto;
    background: #ffffff;
    border: 0.02rem solid rgba(229, 229, 229, 1);
    border-radius: 0.32rem;
    font-size: 0.28rem;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    img {
      width: 0.32rem;
      height: 0.32rem;
      margin-right: 0.16rem;
    }
  }
}
</style>
